import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../service/firebase.service';



@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})

export class PropertiesComponent implements OnInit {
properties = []
imgArr = []
  constructor(private fbService:FirebaseService) { }

  ngOnInit() {
    this.loadProperties()
  }

  loadProperties(){
    this.fbService.getProperties().subscribe(rest=>{
      rest.map(propiedad =>{
        console.log(propiedad);
        
        this.imgArr = []
        const data = propiedad.payload.doc.data();
        const id = propiedad.payload.doc.id;
        if(data['imagenesOrder']){
          data['imagenesOrder'].sort(function(a, b){
            return a.order - b.order;
        });
        }
        if(data['visible'])
          this.properties.push({ id, ...data})
      })
    })
  }

}
