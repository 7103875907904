import { RouterModule, Routes, CanActivate, CanDeactivate } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { MainComponent } from './components/main/main.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { DetalleComponent } from './components/detalle/detalle.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ForoComponent } from './components/foro/foro.component';
import { ForoListaComponent } from './components/foroLista/foroLista.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { AmpiComponent } from './components/ampi/ampi.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { MisionVisionComponent } from './components/mision-vision/mision-vision.component';

const APP_ROUTES:Routes = [
  { path: 'inicio', component: MainComponent},
  { path: 'nosotros', component: AboutComponent},
  { path: 'servicios', component: ServiciosComponent},
  { path: 'mision_vision', component: MisionVisionComponent},
  { path: 'aviso_privacidad', component: AvisoComponent},
  { path: 'propiedades', component: PropertiesComponent},
  { path: 'otras', component: AmpiComponent},
  { path: 'detalle/:uid', component: DetalleComponent},
  { path: 'contacto', component: ContactoComponent},
  { path: 'foro', component: ForoComponent},
  { path: 'listaAsistencia', component: ForoListaComponent},
  { path: 'admin', 
  loadChildren: './admin/admin.module#AdminModule'
  },
	{ path: '**', pathMatch: 'full', redirectTo:'inicio'}
];

export const RUTAS = RouterModule.forRoot(APP_ROUTES);
