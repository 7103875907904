import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../service/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { last } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {
  propiedad: any
  lat = 20.20134575639729
  lng = -104.04866907944336
  constructor(private roter: Router, private route: ActivatedRoute, private fbService: FirebaseService) {
    this.route.params.subscribe(rest => {
      this.getPropertie(rest.uid)
    })
  }

  ngOnInit() {
  }

  getPropertie(uid) {
    this.fbService.gerPropertie(uid).then((propertie) => {
      if (propertie.data() !== undefined) {
        this.propiedad = propertie.data()
        if (this.propiedad['imagenesOrder']) {
          this.propiedad['imagenesOrder'].sort(function (a, b) {
            return a.order - b.order;
          });
        }

      }
      else
        this.roter.navigate(['propiedades'])


    },
      (err) => {
      }
    )

  }

}
