// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false, // production: true => in `enviroment.prod.ts`
  config: {
    apiKey: "AIzaSyAVetgUpvxj7QnDSj0Q-5_7RsuMQXJNKvI",
    authDomain: "ademar-f3d31.firebaseapp.com",
    databaseURL: "https://ademar-f3d31.firebaseio.com",
    projectId: "ademar-f3d31",
    storageBucket: "ademar-f3d31.appspot.com",
    messagingSenderId: "695378820211"
  }
};
