import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }
  endpoint = 'https://us-central1-ademar-f3d31.cloudfunctions.net/httpEmail';

  sendEmail() {
    const data = {
      toEmail: 'rukio948@gmail.com',
      toName: 'Jeff Delaney'
    }

    this.http.post(this.endpoint, data).subscribe()
  }
}
