import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';;


import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { RUTAS } from './app.routes';
import { MainComponent } from './components/main/main.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { DetalleComponent } from './components/detalle/detalle.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderForoComponent } from './components/headerforo/headerforo.component';
import { FooterComponent } from './components/footer/footer.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { AmpiComponent } from './components/ampi/ampi.component';
import { ForoComponent } from './components/foro/foro.component';
import { ForoListaComponent } from './components/foroLista/foroLista.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { MisionVisionComponent } from './components/mision-vision/mision-vision.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Admin Routes
import { AdminModule } from './admin/admin.module';

//AngularFire
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';

//dependencias
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SpinnerComponent } from './components/spinner/spinner.component';

//Paypal
import { FirebaseService } from './components/service/firebase.service';
import { AgmCoreModule } from '@agm/core';

// import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { Firestore, collectionData, collection } from '@angular/fire/firestore';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    MainComponent,
    PropertiesComponent,
    DetalleComponent,
    ContactoComponent,
    HeaderComponent,
    HeaderForoComponent,
    FooterComponent,
    AvisoComponent,
    AmpiComponent,
    ForoComponent,
    ForoListaComponent,
    ServiciosComponent,
    MisionVisionComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    RUTAS,
    HttpClientModule,
    PerfectScrollbarModule,
    AngularFirestoreModule,
    FormsModule, 
    ReactiveFormsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.config),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCAe5WPeRtgwkanuRpGQhHOqkXGMKSDKJY',
      libraries: ['places']
    }),
  ],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  },
    FirebaseService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
