import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseService } from '../service/firebase.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
declare var jQuery:any;
@Component({
  selector: 'app-foroLista',
  templateUrl: './foroLista.component.html',
  styleUrls: ['./foroLista.component.css']
})

export class ForoListaComponent implements OnInit {
  public form: FormGroup;
  usuaros:Array<any>
  error:"Lo sentimos, este correo ya se encuentra registrado actualmente."
  success:string
  tmparr:Array<any>
  @ViewChild('exampleModal') myModal:ElementRef;
  
  constructor(private http: HttpClient, private fbService:FirebaseService, private fb: FormBuilder,) { }
  ngOnInit() {
    this.form= this.fb.group({      
      name: [],
      email: [],
      otro: [],
      inseguridad: [],
      phone:[]
    })
    this.tmparr = []
    this.usuaros =[]
    this.success = "Gracias, su registro se completo exitosamente."
    this.getLista()
  }

  getLista(){
    this.fbService.getForo().subscribe(rest=>{
      rest.map(usuario =>{
        // console.log(usuario);
        
        // this.imgArr = []
        const data = usuario.payload.doc.data();
        const id = usuario.payload.doc.id;        
        if(!this.tmparr.includes(data["phone"])){
          this.usuaros.push({ id, ...data})
          this.tmparr.push(data["phone"])
        }
          
      })
      
    })
    
  }
}
