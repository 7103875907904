import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { FirebaseService } from '../service/firebase.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { timer } from 'rxjs';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  banners: any = []
  properties = []
  showSpinner: boolean = true
  constructor(private db: AngularFirestore,
    private auth: AngularFireAuth,
    private fbService: FirebaseService,
  ) {
    console.log(this.auth.auth.currentUser);

    this.getBanners()
    this.loadProperties()
  }

  ngOnInit() {

  }

  getBanners() {
    return this.db.collection("Banners").snapshotChanges().subscribe(banners => {
      banners.map(banner => {
        const data = banner.payload.doc.data();
        this.banners.push({ data });
      })
      timer(500).subscribe(() => {
        this.showSpinner = !this.showSpinner
      })
    })
  }

  loadProperties() {
    this.fbService.getPropertiesLast().subscribe(rest => {
      rest.map(propiedad => {
        console.log(propiedad);

        const data = propiedad.payload.doc.data();
        const id = propiedad.payload.doc.id;
        if (data['imagenesOrder']) {
          data['imagenesOrder'].sort(function (a, b) {
            return a.order - b.order;
          });
        }
        if (data['visible'])
          this.properties.push({ id, ...data })
      })
    })
  }

}
