import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, DocumentChangeAction } from 'angularfire2/firestore';


@Injectable()
export class FirebaseService {
  user: any
  credentials: any

  constructor(private firebaseAuth: AngularFireAuth,
    private http: HttpClient,
    private db: AngularFirestore,
    private route: Router) {
    this.user = this.firebaseAuth.auth.currentUser
  }
  gerPropertie(uid) {
    return this.db.collection('Properties').ref.doc(uid).get()
  }


  inserPropertie(propiedad, detalles) {
    console.log(propiedad, detalles);

    return this.db.collection('Properties').add({
      propiedad,
      detalles,
      imagenes: [],
      visible: true
    })
  }

  updatePropertie(uid, propiedad, detalles) {
    console.log(propiedad, detalles);

    return this.db.collection('Properties').ref.doc(uid).update({
      propiedad,
      detalles,
      visible: true
    })
  }

  updatePhotos(uid, img) {
    return this.db.collection('Properties').ref.doc(uid).update({
      imagenes: firebase.firestore.FieldValue.arrayUnion(img)
    })
  }

  updateLocation(uid, locations) {
    return this.db.collection('Properties').ref.doc(uid).update({
      locations
    })
  }

  //Edit Propertie



  //grid
  getProperties() {
    return this.db.collection('Properties').snapshotChanges()
  }

  //grid
  getForo() {
    return this.db.collection('Foro').snapshotChanges()
  }

  //grid limit
  //grid
  getPropertiesLast() {
    return this.db.collection('Properties', ref => ref.orderBy("visible", "desc").limit(10)).snapshotChanges()
  }


  // 
  insertRegistro(usuario) {
    const { name, email, phone, otro, inseguridad } = usuario

    return this.db.collection('Foro').add({
      name, email, phone, otro, inseguridad
    })
  }


}