import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseService } from '../service/firebase.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
declare var jQuery:any;
@Component({
  selector: 'app-foro',
  templateUrl: './foro.component.html',
  styleUrls: ['./foro.component.css']
})

export class ForoComponent implements OnInit {
  public form: FormGroup;
  error:"Lo sentimos, este correo ya se encuentra registrado actualmente."
  success:string
  @ViewChild('exampleModal') myModal:ElementRef;
  
  constructor(private http: HttpClient, private fbService:FirebaseService, private fb: FormBuilder,) { }
  ngOnInit() {
    this.form= this.fb.group({      
      name: [],
      email: [],
      otro: [],
      inseguridad: [],
      phone:[]
    })
    this.success = "Gracias, su registro se completo exitosamente."
  }

  addRegister(){
    console.log(this.success);
    const {name,email,phone} = this.form.value
    console.log(name,phone);
    
    if((name !== "" && name !== null) && (phone !== "" && phone !== null))
    this.fbService.insertRegistro(this.form.value).then(res =>{
      jQuery(this.myModal.nativeElement).modal('toggle'); 
      this.form.reset()
     setTimeout(() => {
      jQuery(this.myModal.nativeElement).modal('toggle');
     }, 3000);
    })
    
  }
}
